.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 86px;
  padding: 16px;
  border-radius: 12px;
  color: var(--black-3);
  font-family: Museo, sans-serif;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--white);
  box-sizing: border-box;
  cursor: pointer;

  &.active {
    border: 2px solid var(--green-3);
  }

  img {
    width: 54px;
    height: 54px;
    object-fit: cover;
  }
}
