.wrapper {
  min-height: 100vh;
  padding-top: 220px;
  padding-bottom: 80px;
  background-image: url(../../assets/images/meal_well_symbol.svg);
  background-position: center 100px;
  background-color: var(--light-yellow);
  background-repeat: no-repeat;
  background-size: 61px;
  box-sizing: border-box;
}

.content {
  max-width: var(--meal-content-width);
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;

  :global(.small-text) {
    margin-top: 5px;
  }

  :global(.google-button) {
    margin-top: 16px;
  }
}

.form {
  margin-top: 24px;
  width: 100%;

  &>div:first-child {
    .input {
      margin-top: 0;
    }
  }
}

.input, .select {
  display: block;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  margin-top: 8px;
  font-family: 'MuseoSansCyrl', serif;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.select {
  appearance: none;
  background-image: url(../../assets/images/arrow.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right 16px center;
  outline: none !important;
}

.checkbox {
  display: block;
  margin-left: 12px;
  margin-top: 24px;
  font-size: 12px;
  font-family: 'MuseoSansCyrl', serif;

  a {
    font-weight: bold;
    color: var(--black-3);
  }

  input {
    display: none;

    &:checked+span:before{
      background: url(../../assets/images/checkmark.png) no-repeat;
      background-size: cover;
      border: none;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;

    &:before {
      content: '';
      display: block;
      background: var(--white);
      width: 21px;
      height: 21px;
      border-radius: 3px;
      box-sizing: border-box;
      border: 1px solid var(--green-3);
    }
  }
}

.password {
  margin-top: 24px;
  font-size: 12px;
  font-family: 'MuseoSansCyrl', serif;
}

.input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.input::placeholder {
  color: #5F5F5F;
}

.login {
  margin-top: 24px;
}

.forgotWrapper {
  margin-top: 24px;
  text-align: center;
}

.forgot {
  font-family: 'MuseoSansCyrl', serif;
  font-size: 16px;
  color: var(--green-3);

  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .wrapper {
    box-sizing: border-box;
    padding-top: 191px;
  }
}