.wrapper {
  position: relative;
  background-color: var(--light-yellow);
  min-height: 100vh;
  padding-bottom: 80px;
  box-sizing: border-box;
  
  &:before {
    content: '';
    display: block;
    height: 300px;
    background: url(../../assets/images/get_meal_plan.jpg) no-repeat center;
    background-size: cover;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: calc(50% - 60px);
    top: 240px;
    width: 120px;
    height: 120px;
    background: url(../../assets/images/salmon.png) no-repeat;
    background-size: cover;
  }
}

.content {
  max-width: var(--meal-content-width);
  margin: 75px auto 0;
  padding: 75px 10px 0;
  background: url(../../assets/images/logo.png) no-repeat;
  background-position: center 0;
  background-size: 230px;
  box-sizing: border-box;

  :global(.small-text) {
    margin-top: 5px;
  }

  :global(.google-button) {
    margin-top: 16px;
  }
}

.createButton {
  margin-top: 24px;
}

.already {
  margin-top: 40px;
  font-size: 22px;
}

.login {
  margin-top: 24px;
}

@media (max-width: 768px) {
  .wrapper {

    &:before {
      height: 200px;
    }

    &:after {
      top: 140px;
    }
  }
}