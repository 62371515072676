.wrapper {
  min-height: var(--full-height);
}

.back {
  color: var(--blue);
  font-size: 16px;
  cursor: pointer;
}

.result {
  padding: 20px;
  width: 80vw;
  margin: 50px auto 0;
  font-size: 18px;
  white-space: pre-wrap;
}