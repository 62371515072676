.page {
  background-color: var(--light-yellow);
  font-family: 'LibreBaskerville', sans-serif;
}

.loveSliderWrapper {
  max-width: calc((100vw - var(--scrollbar-width)) -1px);
  /* margin-left: calc((100vw - var(--scrollbar-width)) / 2 - 680px); */
  overflow: hidden;

  .slider {
    margin: 24px auto 0 !important;
  }
}

.slider {
  max-width: 1310px;
  height: 662px;
  margin-top: 24px;
  padding-bottom: 12px !important;
  margin-left: unset !important;
  overflow: visible !important;

  :global(.swiper-pagination-bullet) {
    width: 64px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--white);
    opacity: 1;
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: var(--green-2);
  }
}

.sliderButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 25px 24px 0;
}

.sliderButton {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;

  &.activeSliderButton {
    background-color: var(--green-2);
    color: var(--white);
  }
}

.loveSliderSlide {
  width: 1310px;
}

.loveSliderContent {
  width: 100%;
  height: 630px;
  padding: 148px 96px;
  box-sizing: border-box;
  border-radius: 40px;
  background-color: var(--red-2);
  background-image: url(../../assets/images/love_slider_background_2.png), url(../../assets/images/love_slider_background.png);
  background-repeat: no-repeat;
  background-position: right 193px top 355px, right 110px top 15px;
  background-size: 135px, 440px;
}

.loveSliderInfo {
  width: 480px;
  height: 334px;

  .greenButton {
    margin-top: 16px;
  }

  .usersBlock {
    margin-top: 72px;
  }
}

.loveTitle {
  font-size: 40px;
  font-weight: bold;
  color: var(--white);
}

.loveSubTitle {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  font-family: 'MuseoSansCyrl', sans-serif;
}

.bannerSection {
  position: relative;
  height: 735px;
  border-radius: 32px;
  margin: 0 auto;
  max-width: 1313px;
  background-image: url(../../assets/images/banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0 40px;
  box-sizing: border-box;
}

.play {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  cursor: pointer;
  background-image: url(../../assets/images/play.png);
  background-size: cover;
}

.bannerInfoBlock {
  max-width: 448px;
  overflow: hidden;
  color: var(--white);

  .greenButton {
    margin-top: 20px;
  }
}

.bannerTitle {
  margin-top: 150px;
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
}

.bannerSubTitle {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'MuseoSansCyrl', sans-serif;
}

.greenButton {
  display: block;
  background-color: var(--green-2);
  max-width: 350px;
  height: 64px;
  line-height: 64px;
  border-radius: 12px;
  text-align: center;
  font-size: 24px;
  font-family: 'MuseoSansCyrl', sans-serif;
  font-weight: 600;
  color: var(--white);
  text-decoration: none;
}

.section {
  margin-top: 142px;
  min-height: 200px;
}

.sectionTitle {
  font-size: 40px;
  color: var(--black-3);
  font-weight: bold;
  text-align: center;
}

.sectionSubTitle {
  max-width: 448px;
  text-align: center;
  margin: 16px auto 0;
  font-size: 16px;
  font-family: 'MuseoSansCyrl', sans-serif;
  line-height: 24px;
}

.howItWorksSection {
  margin-top: 165px;
  max-width: calc(100vw - var(--scrollbar-width));

  .greenButton {
    margin: 16px auto 0;
  }
}

.howItWorksWrapper {
  max-width: calc(100vw - var(--scrollbar-width));
  overflow: hidden;

  .slider {
    max-width: 1170px;
    height: 525px;
    margin: 54px auto 0 !important;
    overflow: hidden;
  }

  :global(.swiper-slide) {
    width: auto !important;
    display: flex;
    align-items: center;
  }

  :global(.swiper-pagination) {
    display: none;
  }
}

.howItWorksWrapperMobile {
  display: none;
}

.howItWorksItem {
  background: url(../../assets/images/how_it_work_2.png) var(--white) no-repeat;
  background-size: cover;
  background-position: top;
  width: 380px;
  height: 524px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 450px 24px 0 24px;
  font-size: 16px;
  font-family: 'MuseoSansCyrl', sans-serif;
  line-height: 24px;
}

.howItWorksItem1 {
  background: url(../../assets/images/how_it_work_1.png) var(--white) no-repeat;
  background-size: cover;
  width: 280px;
  height: 350px;
  border-radius: 40px;
}

.nextDaySection {
  margin-top: 200px;
  background-image: none;
  padding: 0 !important;
  height: auto;
  max-width: none;
  background-position: center !important;

  .bannerTitle {
    margin-top: 208px;
  }

  .bannerInfoBlock {
    max-width: none;
    margin-top: 0;
    padding: 0 40px 40px;
    background-image: url(../../assets/images/next-day-banner.jpg);
    background-size: cover;
    background-position: center;
    height: 762px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  }

  .bannerInfoWrapper {
    width: 448px;
    padding: 0;
    overflow: hidden;
  }
}

.marque {
  width: calc(100vw - .5px - var(--scrollbar-width));
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--red-2);
}

.marqueItem {
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 54px 0 44px;
  height: 61px;
  font-size: 24px;
  color: var(--light-yellow);
  font-family: 'MuseoSansCyrl';
  font-weight: bold;
  text-transform: uppercase;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--light-yellow);
  }
}

.shopMarque {
  background-color: var(--white);
}

.shopMarqueItem {
  display: inline-flex;
  height: 107px;
  width: 200px;
  margin: 0 47px;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  &:before {
    display: none;
  }

  &.amazon {
    background-image: url(../../assets/images/shop-1.png);
  }

  &.morrison {
    background-image: url(../../assets/images/shop-2.png);
  }

  &.tesko {
    min-width: 139px;
    background-image: url(../../assets/images/shop-3.png);
  }
}

.whatSaySection {
  margin-top: 90px;
}

.userSayContent {
  position: relative;
  width: 100%;
  height: 675px;
  padding: 148px 96px;
  box-sizing: border-box;
  border-radius: 40px;
  background-color: var(--red-2);

  &:before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/our_users_say.png), url(../../assets/images/slider_mask.svg);
    background-repeat: no-repeat;
    background-position: right 100px bottom, center;
    background-size: 460px, 110%;
  }
}

.userSayWrapper {
  max-width: 1360px;
  margin: 0 auto;
  overflow: hidden;

  .slider {
    max-width: 1360px;
    height: 708px;
    overflow: hidden !important;
  }
}

.userSaySection {
  margin-top: 164px;
}

.userSayInfo {
  width: 448px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.userComment {
  position: absolute;
  padding: auto;
  display: flex;
  gap: 16px;
  width: 263px;
  padding: 13px 14px 22px;
  border-radius: 9px;
  background: linear-gradient(to right, #FFFAE9, #F1DA8E);
}

.sayAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.userCommentTitle {
  font-size: 18px;
  font-weight: bold;
  font-family: 'MuseoSansCyrl', sans-serif;
}

.userCommentSubTitle {
  font-family: 'MuseoSansCyrl', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #383A47;
  opacity: .7;
}

.firstUserComment {
  top: 495px;
  left: 644px;
  /* background-color:  */
}

.secondUserComment {
  top: 358px;
  right: 36px;
}

.loveTitle {
  font-size: 40px;
  font-weight: bold;
  color: var(--white);
}

.loveSubTitle {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  font-family: 'MuseoSansCyrl', sans-serif;
}

.tryFreeToday {
  position: relative;
  background: linear-gradient(to top, #2E7334, #498A2B);
  height: 675px;
  max-width: 1360px;
  border-radius: 60px;
  margin: 0 auto;
  padding: 0 102px;
  overflow: hidden;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    left: calc(50% + -10px);
    top: 0;
    width: 445px;
    height: 700px;
    background-image: url(../../assets/images/try-1.png);
    background-size: cover;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(50% + 155px);
    top: 56px;
    width: 445px;
    height: 645px;
    background-image: url(../../assets/images/try-2.png);
    background-size: cover;
  }
}

.tryContent {
  max-width: 448px;
  height: 224px;
  margin-top: 167px;
  color: var(--white);

  .title {
    font-size: 40px;
    font-weight: bold;
  }

  .subTitle {
    font-family: 'MuseoSansCyrl', sans-serif;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .downloadButton {
    display: block;
    width: 208px;
    height: 60px;
    margin-top: 20px;
    background: url(../../assets/images/app-store.png) no-repeat;
    background-size: cover;
  }
}

.usersBlock {
  width: 780px;
  height: 70px;
  margin-top: 95px;
  display: flex;
  gap: 24px;
  color: var(--white);

  .avatarsBlock {
    position: relative;
    /* display: flex; */
    /* gap: 15px; */
    width: 292px;
    width: 142px;
    white-space: nowrap;
  }

  .avatar {
    position: relative;
    display: inline-block;
    width: 61px;
    height: 61px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -20px;

    &:first-child {
      margin-left: 0;
      background-image: url(../../assets/images/avatar.png);
      z-index: 2;
    }

    &:nth-child(2){
      background-image: url(../../assets/images/avatar-2.png);
      z-index: 1;
    }

    &:nth-child(3) {
      background-image: url(../../assets/images/avatar-3.png);
      z-index: 0;
    }
  }

  .happyCustomers {
    position: relative;
    width: 148px;

    &:first-child:after {
      content: "";
      position: absolute;
      right: -24px;
      top: 17px;
      width: 2px;
      height: 40px;
      background-color: var(--white);
    }
  }

  .rating {
    width: 135px;
    display: flex;
    gap: 48px;

    .customersText {
      display: flex;
      align-items: center;
      font-family: 'MuseoSansCyrl', sans-serif;
    }
  }

  .customersAmount {
    font-size: 32px;
    font-weight: bold;
  }

  .customersText {
    margin-top: 6px;
    font-size: 16px;
    white-space: nowrap;
  }
}

.faqSection {
  padding-bottom: 72px;
  margin-top: 112px;
}

.faqItems {
  max-width: 1090px;
  margin: 48px auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 20px;

  .faqItem {
    &:first-child, &:nth-child(4), &:nth-child(5) {
      background-color: var(--green-2);
      color: var(--white);
    }
  }
}

.faqItem {
  flex: 1 1 calc(50% - 30px);
  box-sizing: border-box;
  padding: 30px;
  border-radius: 30px;
  background-color: var(--white);
}

.faqTitle {
  font-size: 24px;
  line-height: 28px;
}

.faqText {
  margin-top: 10px;
  font-size: 16px;
  line-height: 28px;
  font-family: 'MuseoSansCyrl', sans-serif;
}

.faqMobileItems {
  display: none;
}

@media (max-width: 1420px) {
  .userSayWrapper {
    width: calc(100vw - 40px - var(--scrollbar-width));
    margin: 0 auto;

    .slider {
      width: calc(100vw - 40px - var(--scrollbar-width));
      /* margin: 0 auto; */
    }
  }

  .tryFreeToday {
    width: calc((100vw - 40px - var(--scrollbar-width)));
  }
}

@media (max-width: 1400px) {
  .loveSliderWrapper {
    width: calc((100vw - 48px - var(--scrollbar-width)));
    margin: 24px auto 0;
  }

  .slider {
    overflow: hidden;
  }
}

@media (max-width: 1340px) {
  .bannerSection {
    border-radius: 0;
  }

  .tryFreeToday {
    &:before {
      top: 100px;
      width: 264px;
      height: 396px;
    }

    &:after {
      top: 156px;
      width: 264px;
      height: 364px;
    }
  }
}

@media (max-width: 1200px) {
  .loveSliderContent {
    background-position: right 43px top 355px, right -40px top 15px;
    padding: 148px 40px;
  }
}

@media (max-width: 1040px) {
  .userSayContent {
    padding: 0 36px;
  }

  .firstUserComment {
    left: auto;
    right: 36px;
  }

  .tryFreeToday {
    height: 845px;
    padding: 50px 26px;
    border-radius: 40px;

    &:before {
      top: auto;
      bottom: 9px;
      left: calc(50% - 153px);
    }

    &:after {
      top: auto;
      bottom: 12px;
      left: calc(50% - 60px);
      /* 100 / 156 */
    }

    .rating {
      width: 256px;
    }

    .usersBlock {
      flex-direction: column;
      align-items: center;
      margin: 32px auto 0;
      height: auto;
      width: auto;
    }

    .happyCustomers {
      &:first-child:after {
        top: 8px;
      }
    }

    .customersAmount {
      font-size: 24px;
    }

    .customersText {
      font-size: 12px;
    }
  }

  .tryContent {
    margin: 0 auto;
    height: auto;

    .title {
      font-size: 24px;
      text-align: center;
    }

    .subTitle {
      text-align: center;
    }

    .downloadButton {
      margin: 20px auto 0;
    }
  }
}

@media (max-width: 1000px) {
  .slider {
    height: 880px;
  }

  .loveSliderContent {
    padding: 40px 24px 440px;
    height: 845px;
    background-size: 80px, 328px;
    background-position: calc(50% + 70px) calc(100% - 122px), calc(50% + 40px) calc(100% + 50px);
  }

  .loveSliderInfo {
    width: auto;
    text-align: center;

    .greenButton {
      margin: 16px auto 0;
    }

    .usersBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      width: auto;
      margin-top: 36px;
    }

    .customersText {
      font-size: 14px;
    }
  }

  .loveTitle {
    font-size: 24px;
  }

  .rating {
    justify-content: center;
  }

  .customersAmount {
    font-size: 24px;
  }
}

@media (max-width: 900px) {
  .userSayContent {
    padding: 48px 40px;

    &:before {
      background-size: 250px, 0;
      background-position: center bottom;
    }
  }

  .userSayInfo {
    width: 100%;
    display: block;
  }

  .firstUserComment {
    top: auto;
    bottom: 48px;
    left: calc(50% - 145px);
  }

  .secondUserComment {
    display: none;
  }
}

@media (max-width: 768px) {
  .bannerSection {
    overflow: hidden;
    background-position: calc(50% - 254px);
  }

  .bannerInfoBlock {
    max-width: none;
    margin-top: 378px;
  }

  .bannerTitle {
    margin-top: 0;
    text-align: center;
    font-size: 24px;
  }

  .bannerSubTitle {
    text-align: center;
  }

  .greenButton {
    margin: 20px auto 0;
  }

  .sectionTitle {
    font-size: 24px;
  }

  .section {
    margin-top: 72px;
    min-height: 116px;
  }

  .bannerSection {
    padding: 0 24px;
  }

  .greenButton {
    max-width: 327px;
  }

  .play {
    top: 269px;
  }

  .nextDaySection {
    .bannerInfoWrapper {
      width: auto;
      margin: 44px 24px;
    }

    .bannerInfoBlock {
      height: 706px;
    }

    .bannerTitle {
      margin-top: 0;
    }
  }

  .howItWorksWrapper {
    display: none;
  }

  .howItWorksWrapperMobile {
    display: block;
    width: calc((100vw - var(--scrollbar-width)) -1px);
    overflow: hidden;

    .slider {
      height: 405px;
      width: 295px;
      margin: 37px auto 0 !important;
      padding-bottom: 30px !important;
    }

    .howItWorksItem {
      position: relative;
      width: 295px;
      height: 390px;
      padding: 337px 20px 0 20px;
      font-size: 12px;
      line-height: 16px;
      background-position: 0 -10px;
    }

    .howItWorksItem1 {
      width: 216px;
      height: 270px;
    }

    :global(.swiper-slide) {
      width: auto !important;
      display: flex;
      align-items: center;
    }
  }

  .nextDaySection {
    margin-top: 62px;
  }

  .shopMarqueItem {
    height: 75px;
    width: 150px;
    margin: 0 20px;

    &.tesko {
      width: 138px;
    }
  }

  .userSaySection {
    margin-top: 62px;
  }

  .whatSaySection {
    margin-top: 40px;
  }

  .faqItems {
    display: none;
  }

  .faqMobileItems {
    display: block;
    width: calc(100vw - var(--scrollbar-width) - 40px);
    margin: 0 auto;
    overflow: hidden;

    .slider {
      height: auto;
      padding-bottom: 40px !important;
    }

    :global(.swiper-slide) {

      .faqItem {
        background-color: var(--white);
      }
      
      &:first-child, &:nth-child(4), &:nth-child(5) {
        .faqItem {
          background-color: var(--green-2);
          color: var(--white);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .faqMobileItems {

    .slider {
      padding-bottom: 50px !important;
    }

    :global(.swiper-pagination-bullet) {
      width: 40px;
    }
  }
}

@media (max-width: 420px) {
  .firstUserComment {
    bottom: 20px;
  }
}

@media (max-width: 400px) {
  .loveSliderContent {
    background-position: calc(50% + 70px) calc(100% - 98px), calc(50% + 40px) calc(100% + 74px);
  }
}