.rating {
    display: flex;
    width: 77px;
    height: 13px;
}

.star {
    display: block;
    width: 13px;
    height: 13px;
    background-image: url(../../assets/images/star-white.png);
    background-size: cover;

    &.active {
        background-image: url(../../assets/images/star.png);
    }
}
