.wrapper {
  min-height: 100vh;
  padding-top: 280px;
  padding-bottom: 80px;
  background-image: url(../../assets/images/salmon.png), url(../../assets/images/berries.png), url(../../assets/images/plate-1.png);
  background-position: center 150px, calc(50% + 120px) 77px, calc(50% - 120px) 74px;
  background-color: var(--light-yellow);
  background-repeat: no-repeat;
  background-size: 108px, 100px, 112px;
  box-sizing: border-box;
}

.content {
  max-width: var(--meal-content-width);
  margin: 0 auto;
  padding: 75px 10px 0;
  background: url(../../assets/images/logo.png) no-repeat;
  background-position: center 0;
  background-size: 230px;
  box-sizing: border-box;

  :global(.small-text) {
    margin-top: 5px;
  }

  :global(.google-button) {
    margin-top: 16px;
  }
}

.form {
  margin-top: 24px;
  width: 100%;

  &>div:first-child {
    .input {
      margin-top: 0;
    }
  }
}

.input {
  display: block;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  margin-top: 8px;
  font-family: 'MuseoSansCyrl', serif;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.input::placeholder {
  color: #5F5F5F;
}

.login {
  margin-top: 24px;
}

.forgotWrapper {
  margin-top: 24px;
  text-align: center;
}

.forgot {
  font-family: 'MuseoSansCyrl', serif;
  font-size: 16px;
  color: var(--green-3);

  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .wrapper {
    background-image: url(../../assets/images/salmon.png);
    background-position: center 58px;
    background-size: 120px;
    box-sizing: border-box;
    padding-top: 196px;
  }
}