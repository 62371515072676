.wrapper {
  margin: 64px auto;
  max-width: 980px;
  padding: 0 24px;
  font-size: 16px;
  color: var(--brown);

  h1 {
    margin-top: 40px;
    font-family: Museo, sans-serif;
    font-size: 32px;
    font-weight: bold;
  }

  p {
    margin-top: 20px;
    font-family: Museo, sans-serif;
    font-size: 20px;
    line-height: 24px;
  }

  strong {
    font-weight: bold;
  }

  ul, ol {
    font-family: Museo, sans-serif;
    font-size: 20px;
  }

  ol {
    ul {
      margin-top: 20px;
      list-style-type: disc;
      padding-left: 10px;

    }
  }

  a {
    color: #5B84C2;

    &:hover {
      text-decoration: none;
    }
  }
}

.logo {
  width: 154px;
  height: 36px;
  margin: 0 auto;
  background: url(../../assets/images/logo-text.png) no-repeat;
  background-size: cover;
}

.alphabetList {
  list-style-type: lower-alpha;
  padding-left: 20px;
}

@media (max-width: 1024px) {
  .wrapper {
    h1 {
      font-size: 14px;
    }

    p {
      margin-top: 15px;
      font-size: 14px;
      line-height: 17px;
    }

    ul, ol {
      font-size: 14px;
    }

    ol {
      ul {
        margin-top: 15px;
      }
    }
  }

  .logo {
    width: 110px;
    height: 24px;
  }
}