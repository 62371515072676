.header {
    display: flex;
    flex-direction: row;
    height: 77px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 68px 0 40px;
    box-sizing: border-box;
}

.logo {
    display: block;
    width: 184px;
    height: 29px;
    background-image: url(../../assets/images/logo.png);
    background-size: cover;
}

.menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 24px;
}

.menuLink {
    color: var(--red-2);
    font-size: 16px;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

@media (max-width: 800px) {
    .header {
        padding: 0 24px;

        nav {
            width: 24px;
            height: 24px;
            background: url(../../assets/images/justify.png) no-repeat;
            background-size: contain;
            cursor: pointer;
        }
    }

    .menu {
        display: none;
    }
}