:root {
  --black: #202020;
  --black-2: #1C171E;
  --black-3: #1A1A1A;
  --blue: #0000FF;
  --brown: #393633;
  --brown-background: #efeada;
  --brown-background-hover: #DFD4B6;
  --gray: #8D8D8D;
  --gray-2: #DCDCDC;
  --gray-3: #C6C6C6;
  --green: #184B00;
  --green-2: #2F7133;
  --green-3: #2E7334;
  --green-background: #f2f8eb;
  --green-background-hover: #E5F1D7;
  --light-green: #B7D099;
  --purple: #3E0075;
  --purple-2: #3F0275;
  --red: #E53935;
  --red-2: #C12C64;
  --white: #FFF;
  --yellow: #ffc900;
  --light-yellow: #ECE5A6;
  --content-width: 1410px;
  --meal-content-width: 374px;
  --content-padding: 16px;
  --content-padding-mobile: 28px;
  --header-height: 98px;
  --header-height-mobile: 178px;
  --full-height: calc(var(--vh, 1vh) * 100);
}

@font-face {
  font-family: 'Albra';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Albra-Semi.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 200;
  src: url(./assets/fonts/MuseoSansLighter.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MuseoSans.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/MuseoSansBold.otf) format('opentype');
}

@font-face {
  font-family: 'MuseoSansCyrl';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/MuseoSansCyrl_300.otf) format('opentype');
}

@font-face {
  font-family: 'MuseoSansCyrl';
  font-style: light;
  font-weight: 300;
  src: url(./assets/fonts/MuseoSansCyrl_300.otf) format('opentype');
}

@font-face {
  font-family: 'MuseoSansCyrl';
  font-style: bold;
  font-weight: 700;
  src: url(./assets/fonts/MuseoSansCyrl_700.otf) format('opentype');
}

@font-face {
  font-family: 'LibreBaskerville';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville';
  font-style: italic;
  font-weight: 400;
  src: url(./assets/fonts/LibreBaskerville-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville';
  font-style: bold;
  font-weight: 700;
  src: url(./assets/fonts/LibreBaskerville-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Albra', sans-serif;
}

p, h1, h2 {
  margin: 0;
}

button {
  font-family: Museo, sans-serif;
  border: none;
  padding: 0;
}

input, textarea {
  outline: none;
}


.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}

.medium-text {
  font-family: 'LibreBaskerville', serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--black-3);
}

.small-text {
  font-family: 'MuseoSansCyrl', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--black-3);
}

.green-button, .transparent-button {
  display: block;
  max-width: 354px;
  width: 100%;
  height: 64px;
  line-height: 64px;
  font-family: 'MuseoSansCyrl', sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  border-radius: 12px;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;

  &:disabled {
    background-color: #E8F5E2;
    cursor: not-allowed;
  }
}

.green-button {
  color: var(--white);
  background-color: var(--green-3);
}

.transparent-button {
  color: var(--green-3);
  border: 2px solid var(--green-3);
}

.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 354px;
  height: 64px;
  border-radius: 12px;
  text-decoration: none;
  border: 2px solid var(--green-3);
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    background: url(./assets/images/google.svg) no-repeat;
    background-size: cover;
  }

  &:after {
    content: 'Sign in with Google';
    display: flex;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 24px;
    font-size: 24px;
    font-weight: bold;
    color: var(--green-3);
  }
}

.back-button {
  position: absolute;
  display: block;
  top: 32px;
  left: 40px;
  width: 37px;
  height: 35px;
  background: url(./assets/images/back-button.svg) no-repeat;
  background-size: cover;
}