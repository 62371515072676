.footer {
    background-color: var(--white);
    font-size: 14px;
    line-height: 16.8px;
    font-family: 'MuseoSansCyrl', sans-serif;
}

.footerContent {
    display: flex;
    gap: 54px;
    max-width: 1060px;
    margin: 0 auto;
    padding: 54px 20px;
}

.footerInfo {
    width: 292px;
}

.logo {
    display: block;
    width: 184px;
    height: 29px;
    background-image: url(../../assets/images/logo.png);
    background-size: cover;
}

.infoText {
    margin-top: 36px;
}

.copyright {
    margin-top: 50px;
}

.footerLinks {
    width: 143px;
}

.linksTitle {
    color: var(--red-2);
}

.footerLink {
    display: block;
    text-decoration: none;
    margin-top: 24px;

    &:hover {
        text-decoration: underline;
    }
}

.appStore {
    display: block;
    width: 120px;
    height: 40px;
    margin-top: 8px;
    background-image: url(../../assets/images/app-store-2.png);
    background-size: cover;
}

.socialLinks {
    display: flex;
    gap: 18px;
}

.socialLink {
    display: block;
    width: 38px;
    height: 38px;
    background-size: cover;
}

.facebook {
    background-image: url(../../assets/images/facebook.png);
}

.twitter {
    background-image: url(../../assets/images/twitter.png);
}

.linkedin {
    background-image: url(../../assets/images/linkedin.png);
}

.instagram {
    background-image: url(../../assets/images/instagram.png);
}

@media (max-width: 1130px) {
  .secondLinksBlock {
    display: none;
  }
}

@media (max-width: 1020px) {
  .footerContent {
    gap: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
    padding: 61px 20px;
    gap: 27px;
  }

  .footerInfo {
    width: auto;
  }

  .infoText {
    display: none;
  }

  .copyright {
    display: none;
  }

  .footerLinks {
    text-align: center;
    width: auto;
  }

  .footerLink {
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  .linksTitle {
    display: none;
  }

  .iosLinks {
    order: 5;
    margin-top: 17px;

    .linksTitle {
      display: block;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .socialLinks {
    margin-top: 15px;
  }
}