.stepWrapper {
  position: relative;
  min-height: var(--full-height);
  max-width: 100vw;
  padding-bottom: 50px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--light-yellow);
}

.wideStep {
  :global(.green-button) {
    max-width: 502px;
    margin: 16px auto 0;
  }

  .spaceBetween {
    padding: 0 16px;
  }
}

.wrapperPadding {
  padding: 0 24px;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
}

.greenBackground {
  background-color: var(--green-background);

  &:before {
    background-image: url(../../assets/images/logo.svg);
  }
}

.stepHeader {
  margin-top: 120px;
  font-size: 64px;
  text-align: center;
  color: var(--brown);
}

.stepBody {
  margin-top: 134px;
}

.infoText {
  font-size: 28px;
  font-weight: bold;
}

.label {
  margin-bottom: 6px;
  margin-left: 20px;
  font-family: 'MuseoSansCyrl', sans-serif;
  font-size: 20px;
  color: var(--green);
}

.termsCheckbox {
  margin: 32px auto 0;
  max-width: 500px;
  width: 100%;
}

.termsLabel {
  font-size: 16px;
  font-family: 'MuseoSansCyrl', sans-serif;
  line-height: 14px;
  font-weight: lighter;
}

.link {
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: var(--brown);
}

.stepHeader {
  text-align: center;
  font-size: 24px;
  font-family: 'LibreBaskerville', serif;
  font-weight: bold;
}

.stepDescription {
  margin-top: 4px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: 'MuseoSansCyrl', sans-serif;
}

.nowrap {
  white-space: nowrap;
}

.brownButton, .transparentButton {
  display: block;
  max-width: 500px;
  width: 100%;
  height: 86px;
  margin: 46px auto 0;
  border-radius: 32px;
  font-size: 32px;
  cursor: pointer;
}

.brownButton {
  background-color: var(--brown);
  color: var(--white);

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
}

.transparentButton {
  background-color: transparent;
  border: 1px solid var(--brown);
  color: var(--brown);
  margin: 48px auto 0;
}

.genderList {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 838px;
  margin: 16px auto;
  align-items: center;

  div {
    width: 415px;
    height: 415px;
    padding-top: 325px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: var(--white);
    text-align: center;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: var(--black-3);
    cursor: pointer;
    background-repeat: no-repeat;

    &:first-child {
      background-image: url(../../assets/images/male-2.png);
      background-size: 200px;
      background-position: calc(50% + 10px) 92px;
    }

    &:nth-child(2) {
      background-image: url(../../assets/images/female-2.png);
      background-size: 180px;
      background-position: center 100px;
    }
  }
}

.inputDateWrapper {
  position: relative;
  margin-top: 33px;
}

.inputWrapper {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: 64px auto 0;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;

    top: 0;
    left: 0;
    background-color: var(--white);
    height: 102px;
    width: 100%;
    border-radius: 12px;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 78px;
    left: 16px;
    width: calc(100% - 32px);
    height: 1px;
    border-bottom: 1px solid var(--gray-3);
  }
}

.input, .textarea {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: var(--black-3);
  font-family: 'MuseoSansCyrl', sans-serif;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  z-index: 1;

  &::placeholder {
    color: var(--gray-3);
    font-weight: 200;
    font-weight: bold;
    font-size: 20px;
  }

  &.inputError {
    border-color: var(--red);
    color: var(--red);
  }
}

.input {
  text-align: center;
  height: 102px;

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    color: var(--brown);
    font-size: 32px !important;
    outline: none !important;

    >span {
      padding: 0;
    }
  }

  ul {
    padding: 0;
    border-radius: 24px;
    background: var(--green-background);
    border-color: var(--brown);
  }

  li {
    padding: 20px;
    margin: 0;
    font-size: 32px;
    border-top: 1px solid var(--brown);
    background-color: var(--green-background);

    &:hover {
      background: var(--green-background-hover);
    }

    &:first-child {
      border-top: none;
    }
  }
}

.textarea {
  height: 200px;
  padding: 16px 32px;
}

.selectButtonsWrapper {
  max-width: 500px;
  width: 100%;
  margin: 64px auto 0;
}

.error {
  margin-top: 6px;
  padding-left: 20px;
  color: var(--red);
  font-size: 16px;
  font-weight: lighter;
  font-family: 'MuseoSansCyrl', sans-serif;
}

.radioButtons {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  max-width: 500px;
  width: 100%;
  margin: 16px auto 0;
  box-sizing: border-box;

  label {
    /* flex-grow: 1; */
    width: 33%;
    padding: 16px 12px;
    box-sizing: border-box;
    background-color: var(--white);
    border-radius: 12px;
  }

  input:checked+span {
    &:before {
      background-image: none;
      background-color: transparent;
    }

    &:after {
      content: '';
      position: absolute;
      left: 4px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--green-3);
    }
  }

  span {
    position: relative;
    gap: 8px;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 18px;
    font-weight: bold;

    &:before {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border: 2px solid var(--green-3);
    }
  }
}

.firstPage {
  position: relative;
  box-sizing: border-box;
  
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    left: calc(50% - 160px);
    top: 200px;
    width: 320px;
    height: 320px;
    box-sizing: border-box;
  }

  &:before {
    border: 11px solid var(--white);
    border-radius: 50%;
    background-color: var(--light-yellow);
  }

  &:after {
    left: calc(50% - 150px);
    background: url(../../assets/images/illustration.png) no-repeat;
    background-size: 345px;
  }

  .contentBlock {
    margin-top: 226px;
  }

  :global(.green-button) {
    margin-top: 32px;
  }

  .greenLink {
    margin-top: 35px;
    text-align: center;
  }
}

.firstPageBackground {
  height: 326px;
  width: 100%;
  background: url(../../assets/images/get_meal_plan.jpg) top center;
  background-size: cover;
}

.contentBlock {
  flex-grow: 1;
  width: 354px;
  margin: 0 auto;
}

.greenLink {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  color: var(--green-3);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.roundSlider {
  position: relative;
  min-width: 40vw;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: calc(50% - 40vw);
    width: 80vw;
    height: 80vw;
    background: url(../../assets/images/menus.png) no-repeat;
    background-size: contain;
  }
}

.spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-bottom: 110px;
}

.emailForm {
  position: relative;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .input {
    padding: 0 20px;
  }

  &:after {
    content: "";
    opacity: 0;
    /* Preloading images for animation */
    background-image: url(../../assets/images/plate.png),
                      url(../../assets/images/plate-1.png),
                      url(../../assets/images/plate-2.png),
                      url(../../assets/images/plate-3.png);
  }
}

.finishPage {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  .stepHeader {
    position: relative;
    text-align: center;
    margin-top: 0;

    &:before {
      content: '';
      position: absolute;
      left: calc(50% - 50px);
      top: -140px;
      width: 100px;
      height: 100px;
      background: url(../../assets/images/check.png) no-repeat;
      background-size: cover;
    }
  }
}

.birthDateWrapper {
  
  .stepHeader {
    padding-bottom: 196px;
    background: url(../../assets/images/cake.png) no-repeat;
    background-position: calc(50% - 12px) bottom;
    background-size: 117px;
  }

  :global(.react-datepicker__input-container) div {
    position: relative;
    height: 104px;
    border-radius: 12px;
    background-color: var(--white);
    border: none;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: var(--black-3);
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      left: 16px;
      bottom: 24px;
      height: 1px;
      width: calc(100% - 32px);
      background-color: #C6C6C6;
      display: block;
    }

    &:global(.datapicker-placeholder) {
      color: var(--gray-3);
    }
  }

  :global(.react-datepicker-popper) {    
    transform: translate(0px, 114px) !important;
  }

  :global(.datapicker-wrapper) {
    margin-bottom: 383px;
  }

  :global(.react-datepicker-popper) {
    background-color: var(--white);
    border-radius: 20px;
  }

  :global(.green-button) {
    margin-top: 16px;
  }

  :global(.react-datepicker__header) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    width: 354px;

    h2 {
      font-family: 'LibreBaskerville', serif;
      font-size: 18px;
      font-weight: bold;
    }
  }

  :global(.react-datepicker__year-read-view--selected-year) {
    font-family: 'LibreBaskerville', serif;
    font-size: 18px;
    font-weight: bold;
  }

  :global(.react-datepicker__day-names) {
    width: 100%;
  }

  :global(.react-datepicker__day-name) {
    width: 38px !important;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #5F5F5F;
  }

  :global(.react-datepicker__day) {
    width: 38px !important;
    height: 38px !important;
    line-height: 38px !important;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 18px;
  }

  .mobileDatapicker {
    display: none;
  }
}

.physicalActivity {
  .stepHeader {
    width: 354px;
    margin: 120px auto 0;
  }

  .selectButtonsWrapper {
    width: 354px;
    margin: 16px auto 0;
  }

  :global(.green-button) {
    margin-top: 32px;
  }
}

.strictMenuWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  margin: 172px auto 24px;

  .greenLink {
    margin-top: 35px;
    /* margin: 35px auto 0; */
  }

  :global(.green-button) {
    width: 354px;
    margin-top: -200px;
  }
}

.strictMenu {
  width: 100%;
  border-radius: 12px;
  padding: 344px 24px 223px;
  box-sizing: border-box;
  background: url(../../assets/images/strict-menu.png) var(--white) no-repeat top 44px center;
  background-size: 173px;
  text-align: center;
}

.strictTitle {
  text-align: center;
  font-family: 'LibreBaskerville', serif;
  font-size: 24px;
  font-weight: bold;
}

.strictSubtitle {
  margin-top: 12px;
  text-align: center;
  font-family: 'MuseoSansCyrl', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .stepWrapper {
    overflow: hidden;
  }

  .firstPage {
    flex-direction: column-reverse;
    gap: 48px;
  }

  .contentBlock {
    padding: 0 15px;
    justify-content: space-between;
  }

  .stepBody {
    margin: 0;
    padding-bottom: 104px;
  }

  .brownButton, .transparentButton {
    height: 56px;
    border-radius: 32px;
    font-size: 20px;
  }

  .transparentButton {
    margin-top: 32px;
  }

  .inputWrapper {
    margin-top: 40px;
  }

  .input, .textarea {
    font-size: 20px;

    &::placeholder {
      font-size: 16px;
    }
  }

  .input {

    button {
      font-size: 20px !important;
    }

    li {
      padding: 10px;
      font-size: 20px;
    }
  }

  .textarea {
    height: 163px;
    padding: 18px 24px;
  }

  .error {
    font-size: 12px;
  }

  .selectButtonsWrapper {
    margin-top: 40px;
  }

  .termsCheckbox {
    margin-top: 24px;
  }

  .termsLabel {
    font-size: 12px;
  }

  .contactFormHeader {
    font-size: 24px;
    line-height: 33px;
  }

  .finishPage {

    .stepHeader:before {
      left: calc(50% - 40px);
      top: -115px;
      width: 80px;
      height: 80px;
    }
  }
}

@media (max-width: 768px) {
  .stepHeader {
    margin-top: 60px !important;
  }

  .firstPageBackground {
    display: none;
  }

  .firstPage {
    &:before, &:after {
      top: 245px;
    }

    .contentBlock {
      margin-top: 98px;
    }

    :global(.green-button) {
      margin-top: 466px;
    }
  }

  .stepHeader {
    font-size: 22px;
    line-height: 30px;
  }

  .genderList {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 136px;
      padding: 0;

      &:first-child {
        background-size: 112px;
        background-position: center left 20px;
      }

      &:nth-child(2) {
        background-size: 112px;
        background-position: center left 20px;
      }
    }
  }

  .birthDateWrapper {
    .stepHeader {
      padding-bottom: 285px;
      background-size: 211px;
      background-position: calc(50% - 18px) bottom;
    }

    .inputWrapper {
      margin-top: 24px;
    }

    .mobileDatapicker {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 354px;
      height: 104px;
      opacity: 1;
    }

    .contentBlock {
      max-width: 354px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    :global(.react-datepicker-wrapper) {
      width: 322px;
    }

    :global(.react-datepicker__day-name) {
      width: 30px !important;
    }

    :global(.react-datepicker__day) {
      width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
    }

    :global(.react-datepicker__header) {
      width: 322px;
    }

    :global(.datapicker-wrapper) {
      margin-bottom: 338px;
    }
  }
}

@media (max-width: 600px) {
  .wideRadioButtons {
    justify-content: space-between;

    label span {
      gap: 8px;
    }
  }

  .strictMenuWrapper {
    max-width: none;
    margin: 108px 24px 0;

    :global(.green-button) {
      margin-top: 24px;
      max-width: 100%;
    }
  }

  .strictMenu {
    padding-bottom: 40px;
  }
}
