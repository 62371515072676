.wrapper {
  min-height: var(--full-height);
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  box-sizing: border-box;
}

.authWrapper {
  background-color: var(--light-yellow);
}

.authContent {
  padding: 0 25px;
}
