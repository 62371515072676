.label {
  display: block;
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 500;
  transition: transform 0.2s ease-out;
}

.validationMessage {
  color: var(--red);
  margin-top: 5px;
  font-size: 12px;
  font-family: 'MuseoSansCyrl', serif;
}

.input {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}